import { classdFn } from "classd";
import { always, cond, dec, equals, isNil, T } from "ramda";
import React, { cloneElement, FC, isValidElement, ReactElement, ReactNode, useEffect, useState } from "react";
import styled from "styled-components";

import { ChevronDown } from "../icons/ChevronDown";
import { ChevronUp } from "../icons/ChevronUp";

interface AccordionProps {
  className?: string;
  defaultOpenIndex?: number;
}

interface AccordionItemProps {
  children: ReactNode;
  onToggle?: () => void;
  open?: boolean;
  title: string | ReactElement;
  index?: number;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ChildContainer = styled.div`
  padding: 0 47px 25px;
`;

const ItemContainer = styled.div`
  background-color: white;
  width: 100%;
  border: 1px solid #ebebeb;
  border-radius: 6px;
  position: relative;
  box-shadow: 0 16px 40px rgba(35, 45, 82, 0.08);
  margin-bottom: 1rem;

  & > ${ChildContainer} {
    display: none;
  }

  &.open > ${ChildContainer} {
    display: block;
  }
`;

const Item = styled.a`
  background-color: transparent;
  border: 0 none;
  position: relative;
  padding: 25px 47px;
  width: 100%;
  text-align: left;
`;

const ItemLabel = styled.span`
  font-family: "Neue Haas", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  margin: 0;
  color: black;
`;

const ItemIcon = styled.span`
  position: absolute;
  top: 34px;
  right: 30px;
`;

const getInitialState = cond<number, number>([
  [isNil, always(-1)],
  [equals(-1), always(-1)],
  [T, dec],
]);

export const Accordion: FC<AccordionProps> = ({ children, defaultOpenIndex, className }) => {
  const [openIndex, setOpenIndex] = useState(() => getInitialState(defaultOpenIndex as number));

  return (
    <Container className={className}>
      {React.Children.toArray(children)
        .filter((child) => isValidElement(child))
        .map((child, index) => {
          const selected = openIndex === index;
          // @ts-ignore
          return cloneElement(child, {
            index,
            onToggle: () => (selected ? setOpenIndex(-1) : setOpenIndex(index)),
            open: selected,
          });
        })}
    </Container>
  );
};

Accordion.defaultProps = {
  className: "",
  defaultOpenIndex: -1,
};

export const AccordionItem: FC<AccordionItemProps> = ({ open, onToggle, title, children, index }) => {
  const readableIndex = Number(index) + 1;

  useEffect(() => {
    if (open) {
      const el = document.querySelector(`.accordion-item[href='#${readableIndex}']`);
      if (el) el.scrollIntoView({ behavior: "smooth" });
    }
  }, [open]);

  return (
    <ItemContainer className={classdFn({ open: open })}>
      <Item onClick={onToggle} className="accordion-item inline-block" href={`#${readableIndex}`}>
        <ItemIcon>{open ? <ChevronUp /> : <ChevronDown />}</ItemIcon>
        <ItemLabel>{title}</ItemLabel>
      </Item>
      <ChildContainer>{children}</ChildContainer>
    </ItemContainer>
  );
};

AccordionItem.defaultProps = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onToggle: () => {},
  open: false,
};

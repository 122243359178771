import React, { FC } from "react";

import { RouteComponentProps } from "@reach/router";

import { Accordion, AccordionItem } from "../components/Accordion";
import { PublicRoute } from "../components/PublicRoute";
import { SEO } from "../components/Seo";
import { H1, Typography } from "../components/Typography";

const Faq: FC<RouteComponentProps> = (props) => {
  return (
    <PublicRoute {...props}>
      <SEO title="Faq" />
      <div className="flex flex-1 w-full flex-col max-w-screen-xl mx-auto">
        <H1 className="mb-12" style={{ fontSize: 54, marginTop: "12rem" }}>
          FAQ
        </H1>

        <Accordion>
          <AccordionItem title="Lorem Ipsum">
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat. Duis aute irure dolor in reprehenderit.
            </Typography>
          </AccordionItem>

          <AccordionItem title="Lorem Ipsum">
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat. Duis aute irure dolor in reprehenderit.
            </Typography>
          </AccordionItem>

          <AccordionItem title="Lorem Ipsum">
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat. Duis aute irure dolor in reprehenderit.
            </Typography>
          </AccordionItem>

          <AccordionItem title="Lorem Ipsum">
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat. Duis aute irure dolor in reprehenderit.
            </Typography>
          </AccordionItem>
        </Accordion>
      </div>
    </PublicRoute>
  );
};

export default Faq;

import React, { HTMLAttributes } from "react";

interface Props extends HTMLAttributes<HTMLDivElement> {
  fill: string;
  size: number;
}

function ChevronDown(props: Props): JSX.Element {
  return (
    <div {...props}>
      <svg width="25" height="15" viewBox="0 0 25 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="11.3144" y1="13.3139" x2="23.3352" y2="1.29307" stroke={props.fill} strokeWidth="2" />
        <line
          y1="-1"
          x2="17"
          y2="-1"
          transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 12.0215 14.021)"
          stroke={props.fill}
          strokeWidth="2"
        />
      </svg>
    </div>
  );
}

ChevronDown.defaultProps = {
  fill: "black",
  size: 16,
};

export { ChevronDown };

import React from "react";

interface Props {
  fill: string;
  size: number;
}

function ChevronUp(props: Props): JSX.Element {
  return (
    <div {...props}>
      <svg width="26" height="14" viewBox="0 0 26 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="13.7266" y1="0.707107" x2="1.70582" y2="12.7279" stroke="black" strokeWidth="2" />
        <line
          y1="-1"
          x2="17"
          y2="-1"
          transform="matrix(0.707107 0.707107 0.707107 -0.707107 13.0195 0)"
          stroke="black"
          strokeWidth="2"
        />
      </svg>
    </div>
  );
}

ChevronUp.defaultProps = {
  fill: "black",
  size: 16,
};

export { ChevronUp };
